* {
  font-family: Roboto;
}

input {
  outline: none;
  border: none;
}

body > .skiptranslate {
  display: none;
}

.goog-te-banner-frame.skiptranslate {
  display: none !important;
}
body {
  top: 0px !important;
}
@media print {
  #google_translate_element {
    display: none;
  }
}